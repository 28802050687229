export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro container' style={{padding:"0"}}>
        <div className='overlay'>
          <div className="container-fluid" style={{width:"100%"}}>
            <div className='row' style={{padding:"0"}}>
              <div  className='col-xs-10 col-md-7 intro-text'>
                <div className='col-1' ></div>
                <h1 >
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn About Us
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
