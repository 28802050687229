export const About = (props) => {
  return (
    <div id="about"  className="container center">
      
      <div className="container">
      <h2>About Us</h2>
        <div className="row">
        
          <div className="col-12 col-md-6">
          
            <div className="about-text">
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="  col-12 col-md-6 " style={{padding:"15"}}>
            {" "}
            <img src="img/about.jpg" className="img-fluid hidden-sm hidden-xs" style={{height:"70%", width:"90%", borderRadius:'10px'}} alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
