export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container-fluid' style={{paddingRight: "2.5em"}}  >
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Serving Deschutes, Crook, Jefferson, and Wallowa Counties.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-12 col-md-6 col-lg-4' style={{padding:"0"}}>
                  {' '}
                  <img  style ={{ width: "19em",
  height: "20em",objectFit:"cover", borderRadius:"50%"}} src={d.icon} alt="" ></img>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
              
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
